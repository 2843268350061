import css from 'styled-jsx/css'

export const DropDownStyle = css.global`
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
    color: #ff4d4f;
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
    color: #fff;
    background-color: #ff4d4f;
  }
  .ant-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    display: block;
  }
  .ant-dropdown::before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: -7px;
    left: -7px;
    z-index: -9999;
    opacity: 0.0001;
    content: ' ';
  }
  .ant-dropdown-wrap {
    position: relative;
  }
  .ant-dropdown-wrap .ant-btn > .anticon-down {
    display: inline-block;
    font-size: 10px;
  }
  .ant-dropdown-wrap .anticon-down::before {
    transition: transform 0.2s;
  }
  .ant-dropdown-wrap-open .anticon-down::before {
    transform: rotate(180deg);
  }
  .ant-dropdown-hidden,
  .ant-dropdown-menu-hidden {
    display: none;
  }
  .ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
  .ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
  .ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
    padding-bottom: 10px;
  }
  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
    padding-top: 10px;
  }
  .ant-dropdown-arrow {
    position: absolute;
    z-index: 1;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: transparent;
    border-style: solid;
    border-width: 4.24264069px;
    transform: rotate(45deg);
  }
  .ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
  .ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
  .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    bottom: 6.2px;
    border-top-color: transparent;
    border-right-color: #fff;
    border-bottom-color: #fff;
    border-left-color: transparent;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
    left: 16px;
  }
  .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    right: 16px;
  }
  .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
  .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
  .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    top: 6px;
    border-top-color: #fff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #fff;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  }
  .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
    left: 16px;
  }
  .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    right: 16px;
  }
  .ant-dropdown-menu {
    position: relative;
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-dropdown-menu-item-group-title {
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.45);
    transition: all 0.3s;
  }
  .ant-dropdown-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    background: transparent;
    box-shadow: none;
  }
  .ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
    transform-origin: 0 0;
  }
  .ant-dropdown-menu-submenu-popup ul,
  .ant-dropdown-menu-submenu-popup li {
    list-style: none;
  }
  .ant-dropdown-menu-submenu-popup ul {
    margin-right: 0.3em;
    margin-left: 0.3em;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    clear: both;
    margin: 0;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-dropdown-menu-item > .anticon:first-child,
  .ant-dropdown-menu-submenu-title > .anticon:first-child,
  .ant-dropdown-menu-item > span > .anticon:first-child,
  .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
    min-width: 12px;
    margin-right: 8px;
    font-size: 12px;
  }
  .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-submenu-title > a {
    display: block;
    margin: -5px -12px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    transition: all 0.3s;
  }
  .ant-dropdown-menu-item > a:hover,
  .ant-dropdown-menu-submenu-title > a:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-dropdown-menu-item > .anticon + span > a,
  .ant-dropdown-menu-submenu-title > .anticon + span > a {
    color: rgba(0, 0, 0, 0.85);
    transition: all 0.3s;
  }
  .ant-dropdown-menu-item > .anticon + span > a:hover,
  .ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-submenu-title-selected,
  .ant-dropdown-menu-item-selected > a,
  .ant-dropdown-menu-submenu-title-selected > a {
    color: var(--primary);
    background-color: #e6f7ff;
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #f5f5f5;
  }
  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-dropdown-menu-item-disabled:hover,
  .ant-dropdown-menu-submenu-title-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }
  .ant-dropdown-menu-item-divider,
  .ant-dropdown-menu-submenu-title-divider {
    height: 1px;
    margin: 4px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #f0f0f0;
  }
  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    position: absolute;
    right: 8px;
  }
  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    margin-right: 0;
    color: rgba(0, 0, 0, 0.45);
    font-style: normal;
    display: inline-block;
    font-size: 10px;
  }
  .ant-dropdown-menu-item-group-list {
    margin: 0 8px;
    padding: 0;
    list-style: none;
  }
  .ant-dropdown-menu-submenu-title {
    padding-right: 24px;
  }
  .ant-dropdown-menu-submenu-vertical {
    position: relative;
  }
  .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 100%;
    margin-left: 4px;
    transform-origin: 0 0;
  }
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }
  .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
    color: var(--primary);
  }
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
    animation-name: antSlideUpIn;
  }
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
    animation-name: antSlideDownIn;
  }
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
    animation-name: antSlideUpOut;
  }
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
    animation-name: antSlideDownOut;
  }
  .ant-dropdown-trigger > .anticon.anticon-down,
  .ant-dropdown-link > .anticon.anticon-down,
  .ant-dropdown-button > .anticon.anticon-down {
    vertical-align: baseline;
    display: inline-block;
    font-size: 10px;
  }
  .ant-dropdown-button {
    white-space: nowrap;
  }
  .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-dropdown-menu-dark,
  .ant-dropdown-menu-dark .ant-dropdown-menu {
    background: #001529;
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
    color: #fff;
    background: transparent;
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
    color: #fff;
    background: var(--primary);
  }
  .ant-dropdown-rtl {
    direction: rtl;
  }
  .ant-dropdown-rtl.ant-dropdown::before {
    right: -7px;
    left: 0;
  }
  .ant-dropdown-menu.ant-dropdown-menu-rtl {
    direction: rtl;
    text-align: right;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
    direction: rtl;
    text-align: right;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
    text-align: right;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
    text-align: right;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
  .ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    right: auto;
    left: 8px;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    margin-left: 0;
    transform: scaleX(-1);
  }
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
    padding-right: 12px;
    padding-left: 24px;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    right: 100%;
    left: 0;
    margin-right: 4px;
    margin-left: 0;
  }
`
