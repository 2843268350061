import React, { FC } from 'react'
import { WrapperLayout } from 'src/@legacy/@core/components/share/Wrapper/WrapperLayout'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { NewsSectionStyle } from 'src/@legacy/style/styled/LP/NewsSectionStyle.style'
import SlickSlider from 'src/@legacy/@core/components/share/SlickSlider'
import { LIST_NEWS } from 'src/@legacy/utilities/Enum'
import Image from 'next/image'
import { RightOutlined } from '@ant-design/icons'

const NewsSection: FC = () => {
  const { translate } = useTranslation()
  const settings = {
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: (
      <button className="slick-prev">
        <img width={40} height={40} src="/img/LP/arrow_left.png" alt="arrow-left" className="img-fluid" />
      </button>
    ),
    nextArrow: (
      <button className="slick-next">
        <img width={40} height={40} src="/img/LP/arrow_right.png" alt="arrow-right" className="img-fluid" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }
  return (
    <>
      <style jsx>{NewsSectionStyle}</style>
      <div className="news-section">
        <WrapperLayout className="wrapper-container">
          <h2 className="heading">{translate('page_employer_lp:news_section_heading')}</h2>
          <div className="block-right">
            <SlickSlider {...settings}>
              {LIST_NEWS.map((item) => (
                <a href={item.url} target="blank" className="card-wrapper" key={item.id}>
                  <div className="image-wrapper">
                    <Image quality={100} src={item.image} alt={`image-${item.id}`} width={384} height={252} />
                  </div>
                  <div className="content">
                    <div className="title">{item.title}</div>
                    <div className="detail">{item.content}</div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="logo-wrapper">
                        <Image quality={100} src={item.logo} alt={`logo-${item.id}`} width={156} height={32} />
                      </div>
                      <div className="btn-view">
                        {translate('page_employer_lp:news_section_button_view')}
                        <RightOutlined className="ml-2" />
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </SlickSlider>
          </div>
        </WrapperLayout>
      </div>
    </>
  )
}

export default NewsSection
