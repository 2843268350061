import { CloseOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Button } from 'src/@legacy/@core/components/share/ANTD/Button'
import { Drawer } from 'src/@legacy/@core/components/share/ANTD/Drawer'
import { AppLink } from 'src/@legacy/@core/components/share/AppLink'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import Image from 'next/image'
import React, { FC, useState } from 'react'
import { RESOURCE_IMAGE_ICON_CANDIDATE, RESOURCE_IMAGE_FREEC, RESOURCE_IMAGE_FREEC_LOGO } from '../utilities/Constant'

import { RESOURCE_IMAGE_EN_FLAG, RESOURCE_IMAGE_VN_FLAG } from '../@core/utilities/Constant'
import {
  LINK_URL_LOGIN,
  LINK_URL_ROOT,
  LINK_URL_CANDIDATE,
  LINK_URL_EMPLOYER_CONTACT,
  LINK_URL_EMPLOYER_HR_GLOSSARY,
  LINK_URL_EMPLOYER_JD_EXAMPLES
} from '../utilities/LinkURL'
import { LINK_URL_REGISTER } from '../utilities/LinkURL'
import useAppRouter from '../@optimal-page/hooks/useAppRouter'
import { Divider } from '../@core/components/share/ANTD'

const SideBarBeforeLogin: FC = () => {
  const { currentLanguage, changeLanguage, translate } = useTranslation()
  const [showSidebar, setShowSidebar] = useState(false)
  const toggleSideBar = () => {
    setShowSidebar(!showSidebar)
  }
  const router = useAppRouter()

  const footer = (
    <>
      <button
        type="button"
        className="btn btn-employer"
        onClick={() => router.push(LINK_URL_CANDIDATE, LINK_URL_CANDIDATE, { keepUTM: true })}>
        <div className="d-flex w-100 align-items-center">
          {/* <div className="icon-left d-flex align-items-center justify-content-center">
            <Image src={RESOURCE_IMAGE_ICON_CANDIDATE} alt="freec-platform" width={40} height={40} />
          </div> */}
          <div className="btn-info">
            <div className="title">{translate('header:for_job_seeker')}</div>
            <div className="sub-title">{translate('header:btn_job_seeker_sub')}</div>
          </div>
          <div className="icon-right">
            <ArrowRightOutlined style={{ fontSize: 24, color: 'var(--primary)' }} />
          </div>
        </div>
      </button>
    </>
  )

  const title = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="sidebar-lang">
        <button
          className={`btn-lang ${currentLanguage === 'vi' ? 'activeLang' : ''}`}
          onClick={() => {
            changeLanguage('vi')
          }}>
          <Image src={RESOURCE_IMAGE_VN_FLAG} alt="Vienamese" width={25} height={16} />
          <span style={{ marginLeft: 5 }}>VN</span>
        </button>
        <button
          className={`btn-lang ${currentLanguage === 'en' ? 'activeLang' : ''}`}
          onClick={() => {
            changeLanguage('en')
          }}>
          <Image src={RESOURCE_IMAGE_EN_FLAG} alt="English" width={25} height={16} />
          <span style={{ marginLeft: 5 }}>EN</span>
        </button>
      </div>
      <Button className="btn-close-sidebar" onClick={toggleSideBar}>
        <CloseOutlined style={{ fontSize: 24 }} />
      </Button>
    </div>
  )

  return (
    <div className="sidebar">
      <button id="nav-icon" className={`${showSidebar ? 'open' : ''}`} onClick={toggleSideBar}>
        <div className="wrap-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div className="logo">
        <AppLink keepUTM href={LINK_URL_ROOT}>
          <a className="header-logo-img">
            <div className="logo-freec">
              <Image quality={100} src={RESOURCE_IMAGE_FREEC_LOGO} alt="freec-platform" width={100} height={40} />
            </div>
          </a>
        </AppLink>
        {/* <AppLink keepUTM prefetch={false} href={LINK_URL_REGISTER} as={LINK_URL_REGISTER}>
          <a>
            <button onClick={toggleSideBar} className="btn-link">
              {translate('header:register')}
            </button>
          </a>
        </AppLink> */}
      </div>

      <Drawer
        className="sidebar-before-login"
        placement="right"
        title={title}
        footer={footer}
        width="320"
        closable={false}
        onClose={toggleSideBar}
        visible={showSidebar}>
        <div className="group-button group-button-before group-button-employer">
          <Button className="btn" size="large">
            <AppLink keepUTM prefetch={false} href={LINK_URL_LOGIN} as={LINK_URL_LOGIN}>
              <a onClick={toggleSideBar}>{translate('button:login')}</a>
            </AppLink>
          </Button>
          <Button type="primary" className="btn" size="large">
            <AppLink keepUTM prefetch={false} href={LINK_URL_REGISTER} as={LINK_URL_REGISTER}>
              <a onClick={toggleSideBar}>{translate('button:register')}</a>
            </AppLink>
          </Button>
        </div>

        <AppLink keepUTM href={LINK_URL_EMPLOYER_CONTACT}>
          <a
            onClick={() => {
              toggleSideBar()
            }}
            className="btn-link">
            {translate('footer:contact_us')}
          </a>
        </AppLink>
        <Divider />
        <div className="btn-link-group">Resources</div>
        <AppLink keepUTM href={LINK_URL_EMPLOYER_HR_GLOSSARY}>
          <a
            onClick={() => {
              toggleSideBar()
            }}
            className="btn-link">
            {translate('footer:hr_glossary')}
          </a>
        </AppLink>
        <AppLink keepUTM href={LINK_URL_EMPLOYER_JD_EXAMPLES}>
          <a
            onClick={() => {
              toggleSideBar()
            }}
            className="btn-link">
            {translate('footer:jd_examples')}
          </a>
        </AppLink>
      </Drawer>
    </div>
  )
}

export default SideBarBeforeLogin
