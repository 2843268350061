import css from 'styled-jsx/css'

export const FeatureSectionStyle = css.global`
  .feature-section {
    padding: 40px 0;
    background-color: #fff;
    position: relative;
  }
  .feature-section .wrapper-container {
    position: relative;
    z-index: 2;
  }
  .feature-section .image-background {
    top: -250px;
    right: 0;
    position: absolute;
    z-index: 1;
  }
  .feature-section .heading {
    font-size: 36px;
    line-height: 48px;
    font-weight: 600;
    color: var(--gray-900);
    margin-bottom: 62px;
    text-align: center;
  }
  .feature-section .content .title {
    font-size: 30px;
    line-height: 40px;
    color: var(--gray-700);
    margin-bottom: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .feature-section .content .sub-title {
    font-size: 20px;
    line-height: 26px;
    color: var(--gray-700);
  }
  .tag-beta {
    padding: 0px 8px;
    border-radius: 30px;
    background-color: #52c41a;
    color: #fff;
    font-size: 16px;
    width: 50px;
    height: 26px;
    display: flex;
    align-items: center;
    margin-left: 4px;
  }
  @media screen and (max-width: 768px) {
    .feature-section {
      padding: 20px 0;
    }
    .feature-section .image-background {
      display: none;
    }
    .feature-section .heading {
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 32px;
    }
    .feature-section .image-wrapper > div {
      width: 100%;
      height: 100%;
    }
    .feature-section .content .title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;
    }
    .feature-section .content .sub-title {
      font-size: 14px;
      line-height: 22px;
    }
    .feature-section .order-under {
      order: 2;
    }
    .tag-beta {
      width: 45px;
      height: 24px;
      font-size: 14px;
    }
  }
`
