import React, { FC } from 'react'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { WhySectionStyle } from 'src/@legacy/style/styled/LP/WhySectionStyle.style'
import { WrapperLayout } from 'src/@legacy/@core/components/share/Wrapper/WrapperLayout'
import { Row, Col } from 'src/@legacy/@core/components/share/ANTD/Grid'
import Image from 'next/image'
import { Trans } from 'react-i18next'

const WhySection: FC = () => {
  const { translate } = useTranslation()
  return (
    <>
      <style jsx>{WhySectionStyle}</style>
      <div className="why-section">
        <WrapperLayout className="wrapper-container">
          <h2 className="heading">{translate('page_employer_lp:why_section_heading')}</h2>
          <Row justify="center">
            <Col xs={24} md={24} lg={22}>
              <Row gutter={[32, 32]}>
                <Col xs={24} md={12} lg={8}>
                  <div className="block-wrapper">
                    <div className="image-wrapper">
                      <Image src="/img/LP/why_1.png" width={56} height={56} alt="why_1" />
                    </div>
                    <div className="description">
                      <Trans i18nKey="page_employer_lp:why_section_detail_1">
                        <span className="high-light" />
                        <div />
                      </Trans>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <div className="block-wrapper">
                    <div className="image-wrapper">
                      <Image src="/img/LP/why_2.png" width={56} height={56} alt="why_2" />
                    </div>
                    <div className="description">
                      <Trans i18nKey="page_employer_lp:why_section_detail_2">
                        <span className="high-light" />
                      </Trans>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <div className="block-wrapper">
                    <div className="image-wrapper">
                      <Image src="/img/LP/why_3.png" width={56} height={56} alt="why_3" />
                    </div>
                    <div className="description">
                      <Trans i18nKey="page_employer_lp:why_section_detail_3" values={{ number: process.env.NEXT_PUBLIC_NUMBER_CANDIDATES }}>
                        <span className="high-light" />
                      </Trans>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <div className="block-wrapper">
                    <div className="image-wrapper">
                      <Image src="/img/LP/why_4.png" width={56} height={56} alt="why_4" />
                    </div>
                    <div className="description">
                      <Trans i18nKey="page_employer_lp:why_section_detail_4">
                        <span className="high-light" />
                      </Trans>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <div className="block-wrapper">
                    <div className="image-wrapper">
                      <Image src="/img/LP/why_5.png" width={56} height={56} alt="why_5" />
                    </div>
                    <div className="description">
                      <Trans i18nKey="page_employer_lp:why_section_detail_5">
                        <span className="high-light" />
                      </Trans>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <div className="block-wrapper">
                    <div className="image-wrapper">
                      <Image src="/img/LP/why_6.png" width={56} height={56} alt="why_6" />
                    </div>
                    <div className="description">
                      <Trans i18nKey="page_employer_lp:why_section_detail_6">
                        <span className="high-light" />
                      </Trans>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </WrapperLayout>
      </div>
    </>
  )
}

export default WhySection
