import React, { FC, useEffect } from 'react'
import { createUseLocalStorageStage } from 'src/@legacy/@core/hooks/share/StorageHooks'

export const LIST_COLORS = [
  '#E53935',
  '#E91E63',
  '#AA00FF',
  '#6200EA',
  '#304FFE',
  '#0D47A1',
  '#607D8B',
  '#00ACC1',
  '#009688',
  '#4CAF50',
  '#C0CA33',
  '#F9A825',
  '#F57C00',
  '#795548'
]
const [useColorAvatar] = createUseLocalStorageStage<string>('@color-avatar')
const AvatarDefault: FC<{ name: string; size?: number }> = ({ name, size = 40 }) => {
  const [colorAvatar, setColorAvatar] = useColorAvatar()
  const defaultColor = '#6d4fed'
  useEffect(() => {
    if (!colorAvatar) {
      randomColor()
    }
  }, [colorAvatar])

  const marginRight = 5
  const textColor = 'white'
  const randomColor = () => {
    const colorId = Math.floor(Math.random() * LIST_COLORS.length)
    setColorAvatar(LIST_COLORS[colorId])
  }

  const getBestFontSize = (size) => {
    const availableSizes = [8, 16, 32, 64, 128]
    let bestFontSize = availableSizes[availableSizes.length - 1]
    for (let i = 0; i < availableSizes.length; i++) {
      const thresholdSize = 3 * availableSizes[i]
      if (size <= thresholdSize) {
        bestFontSize = availableSizes[i]
        break
      }
    }
    return bestFontSize
  }

  const getShortName = () => {
    if (name) {
      const words = name.trim().split(' ')
      if (name.length == 0) {
        return 'N/A'
      } else if (words.length == 1) {
        return words[0][0].toUpperCase()
      } else {
        return (words[0][0] + words[words.length - 1][0]).toUpperCase()
      }
    }
    return 'N/A'
  }

  return (
    <div className="d-flex justify-content-center">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          marginRight,
          width: size,
          height: size,
          borderRadius: size / 2,
          background: colorAvatar || defaultColor
        }}>
        <span style={{ color: textColor, fontSize: getBestFontSize(size || 40) }}>{getShortName()}</span>
      </div>
    </div>
  )
}
export default AvatarDefault
