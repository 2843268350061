export const RESOURCE_IMAGE_FREEC_LOGO_EN = '/images/logo_employer_en.svg'
export const RESOURCE_IMAGE_FREEC_LOGO_VN = '/images/logo_employer_vn.svg'
export const RESOURCE_IMAGE_FREEC_LOGO = '/images/logo_employer.svg'
export const RESOURCE_IMAGE_FREEC_LOGO_WHITE = '/images/logo_employer_white.svg'
export const RESOURCE_IMAGE_FREEC = '/images/logo_freec.svg'
export const RESOURCE_IMAGE_EN_FLAG = '/img/en_flag.png'
export const RESOURCE_IMAGE_VN_FLAG = '/img/vn_flag.png'
export const RESOURCE_IMAGE_FREEC_PLATFORM = '/imgLP/freec_platform.png'
export const RESOURCE_IMAGE_DOWNLOAD_ANDROID = '/img/chplay.png'
export const RESOURCE_IMAGE_DOWNLOAD_IOS = '/img/appstore.png'
export const RESOURCE_IMAGE_DOWNLOAD_QRCODE = '/img/qr-code-mobile.png'
export const RESOURCE_IMAGE_DOWNLOAD_ANDROID_BLACK = '/img/logo_google_play_black.png'
export const RESOURCE_IMAGE_DOWNLOAD_IOS_BLACK = '/img/logo_apple_store_black.png'
export const RESOURCE_IMAGE_LOGO_GOV = '/img/logo_gov_red.png'
export const RESOURCE_IMAGE_ICON_BUILDINGS = '/img/buildings.svg'
export const RESOURCE_IMAGE_ICON_CANDIDATE = '/img/candidate_icon.webp'
export const RESOURCE_IMAGE_ICON_USER = '/img/user.webp'
export const RESOURCE_IMAGE_ARROW_RIGHT_BLUE = '/img/arrow-right-blue.svg'
export const RESOURCE_IMAGE_HEADER_ARROW_RIGHT = '/img/header_arrow_right.svg'
export const RESOURCE_IMAGE_ICON_LANGUAGE = '/img/language_icon.webp'

export const COOKIES_NAME_REFERRAL_ID = 'referral-id'
export const COOKIES_NAME_UTM_SOURCE = 'utm_source'
export const COOKIES_NAME_UTM_CAMPAIGN = 'utm_campaign'
export const COOKIES_NAME_UTM_MEDIUM = 'utm_medium'
export const COOKIES_NAME_LANGUAGE = 'X_LANGUAGE'

export const DEFAULT_LOCALE = 'vi'

export const PATTERN_VALIDATION_UNICODE = /^[aAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐeEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆfFgGhHiIìÌỉỈĩĨíÍịỊjJkKlLmMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsStTuUùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ\s]*$/
export const USER_TYPE_CANDIDATE = 1
export const USER_TYPE_COMPANY = 0
export const MOMENT_DATE_FORMAT_PARTTERN = 'DD/MM/YYYY'
export const MOMENT_DAY_MONTH_FORMAT_PARTTERN = 'DD/MM/YYYY'
export const MOMENT_MONTH_YEAR_FORMAT_PARTTERN = 'DD/MM'
export const MOMENT_MONTH_FORMAT_PARTTERN = 'MM/YYYY'
export const MOMENT_DATE_TIME_FORMAT_PARTTERN = 'DD/MM/YYYY hh:mm'
export const MOMENT_DATE_SERVER_TIN_FORMAT = 'YYYY-MM-DD'
export const MOMENT_DATE_DDD_MM_YYYY = 'DD MMM YYYY'
/**
 * Page items
 */
export const PAGE_ITEMS = 10
export const PAGE_ITEMS_SEARCH_JOBS = 30
export const PAGE_ITEMS_JOB_DETAIL_SIMILAR_JOBS = 6
export const PAGE_ITEMS_COMPANY_DETAIL_JOBS = 20
export const PAGE_ITEMS_COMPANY_LIST_PUBLIC = 30

export const USER_JOB_ACTION_TYPE_RECRUITER = 1
export const LOGO_DEFAULT = '/img/logo_default.png'
export const AVATAR_DEFAULT = '/img/avatar_default.png'
export const THUMBNAIL_CANDIDATE_LP = '/img/candidate_LP.jpg'
export const THUMBNAIL_CANDIDATE_PUBLIST_PROFILE = '/img/candidate_public_profile.jpg'
export const THUMBNAIL_COMPANY_DETAIL = '/img/company_detail.jpg'
export const THUMBNAIL_COMPANY_LP_HH = '/img/company_lp_hh.jpg'
export const THUMBNAIL_COMPANY_LP = '/img/company_lp.jpg'
export const THUMBNAIL_JOB_DETAIL = '/img/search_job.jpg'
export const THUMBNAIL_SEARCH_JOB = '/img/search_job.jpg'
export const THUMBNAIL_SHARE_JOB_SOCIAL = '/img/share_job_social.jpg'
export const LOGIN_PROVIDER_GOOGLE = 1
export const LOGIN_PROVIDER_FACEBOOK = 0
export const CV_MAX_SIZE_MB = 5

export const PROFILE_SOURCE_LINKEDIN = 0
export const PROFILE_SOURCE_REFERRAL = 1
export const PROFILE_SOURCE_FACEBOOK = 2
export const PROFILE_SOURCE_FREEC = 3
export const PROFILE_SOURCE_CAREEBUILDER = 4
export const PROFILE_SOURCE_EMAIL = 5
export const PROFILE_SOURCE_RESEARCHER = 6
export const PROFILE_SOURCE_JOBSITE = 7
export const PROFILE_SOURCE_GITHUB = 8
export const PROFILE_SOURCE_OTHER = 9

export const ENUM_LANGUAGE_NUM_VI = 1
export const ENUM_LANGUAGE_NUM_EN = 0
