import css from 'styled-jsx/css'

export const MenuStyle = css.global`
  .ant-menu-item-danger.ant-menu-item {
    color: #ff4d4f;
  }
  .ant-menu-item-danger.ant-menu-item:hover,
  .ant-menu-item-danger.ant-menu-item-active {
    color: #ff4d4f;
  }
  .ant-menu-item-danger.ant-menu-item:active {
    background: #fff1f0;
  }
  .ant-menu-item-danger.ant-menu-item-selected {
    color: #ff4d4f;
  }
  .ant-menu-item-danger.ant-menu-item-selected > a,
  .ant-menu-item-danger.ant-menu-item-selected > a:hover {
    color: #ff4d4f;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    background-color: #fff1f0;
  }
  .ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
    border-right-color: #ff4d4f;
  }
  .ant-menu-dark .ant-menu-item-danger.ant-menu-item,
  .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
    color: #ff4d4f;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    color: #fff;
    background-color: #ff4d4f;
  }
  .ant-menu {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: 'tnum';
    margin-bottom: 0;
    padding-left: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 0;
    text-align: left;
    list-style: none;
    background: #fff;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  }
  .ant-menu::before {
    display: table;
    content: '';
  }
  .ant-menu::after {
    display: table;
    clear: both;
    content: '';
  }
  .ant-menu::before {
    display: table;
    content: '';
  }
  .ant-menu::after {
    display: table;
    clear: both;
    content: '';
  }
  .ant-menu ul,
  .ant-menu ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .ant-menu-hidden {
    display: none;
  }
  .ant-menu-item-group-title {
    height: 1.5715;
    padding: 8px 16px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    transition: all 0.3s;
  }
  .ant-menu-horizontal .ant-menu-submenu {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-submenu,
  .ant-menu-submenu-inline {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-submenu-selected {
    color: var(--primary);
  }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: #e6f7ff;
  }
  .ant-menu-submenu .ant-menu-sub {
    cursor: initial;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-menu-item a:hover {
    color: var(--primary);
  }
  .ant-menu-item a::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    content: '';
  }
  .ant-menu-item > .ant-badge a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-menu-item > .ant-badge a:hover {
    color: var(--primary);
  }
  .ant-menu-item-divider {
    height: 1px;
    overflow: hidden;
    line-height: 0;
    background-color: #f0f0f0;
  }
  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: var(--primary);
  }
  .ant-menu-horizontal .ant-menu-item,
  .ant-menu-horizontal .ant-menu-submenu {
    margin-top: -1px;
  }
  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
    background-color: transparent;
  }
  .ant-menu-item-selected {
    color: var(--primary);
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: var(--primary);
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 1px solid #f0f0f0;
  }
  .ant-menu-vertical-right {
    border-left: 1px solid #f0f0f0;
  }
  .ant-menu-vertical.ant-menu-sub,
  .ant-menu-vertical-left.ant-menu-sub,
  .ant-menu-vertical-right.ant-menu-sub {
    min-width: 160px;
    max-height: calc(100vh - 100px);
    padding: 0;
    overflow: hidden;
    border-right: 0;
    transform-origin: 0 0;
  }
  .ant-menu-vertical.ant-menu-sub:not([class*='-active']),
  .ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
  .ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ant-menu-vertical.ant-menu-sub .ant-menu-item,
  .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
  .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0;
  }
  .ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
  .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
  .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
    border-right: 0;
  }
  .ant-menu-vertical.ant-menu-sub > .ant-menu-item,
  .ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
  .ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
  .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
  .ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
  .ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
    transform-origin: 0 0;
  }
  .ant-menu-horizontal.ant-menu-sub {
    min-width: 114px;
  }
  .ant-menu-horizontal .ant-menu-item,
  .ant-menu-horizontal .ant-menu-submenu-title {
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    position: relative;
    display: block;
    margin: 0;
    padding: 0 20px;
    white-space: nowrap;
    cursor: pointer;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item .anticon + span,
  .ant-menu-submenu-title .anticon + span {
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item.ant-menu-item-only-child > .anticon,
  .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
    margin-right: 0;
  }
  .ant-menu > .ant-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    padding: 0;
    overflow: hidden;
    line-height: 0;
    background-color: #f0f0f0;
  }
  .ant-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    border-radius: 2px;
    box-shadow: none;
  }
  .ant-menu-submenu-popup::before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.0001;
    content: ' ';
  }
  .ant-menu-submenu-placement-rightTop::before {
    top: 0;
    left: -7px;
  }
  .ant-menu-submenu > .ant-menu {
    background-color: #fff;
    border-radius: 2px;
  }
  .ant-menu-submenu > .ant-menu-submenu-title::after {
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-submenu-popup > .ant-menu {
    background-color: #fff;
  }
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 10px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    position: absolute;
    width: 6px;
    height: 1.5px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85));
    border-radius: 2px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: '';
  }
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    transform: rotate(45deg) translateY(-2px);
  }
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(-45deg) translateY(2px);
  }
  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: linear-gradient(to right, #6d4fed, #6d4fed);
  }
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    transform: rotate(45deg) translateY(-2px);
  }
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(-45deg) translateY(2px);
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    transform: rotate(-45deg) translateX(2px);
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(45deg) translateX(-2px);
  }
  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    transform: translateY(-2px);
  }
  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(-45deg) translateX(-2px);
  }
  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    transform: rotate(45deg) translateX(2px);
  }
  .ant-menu-vertical .ant-menu-submenu-selected,
  .ant-menu-vertical-left .ant-menu-submenu-selected,
  .ant-menu-vertical-right .ant-menu-submenu-selected {
    color: var(--primary);
  }
  .ant-menu-horizontal {
    line-height: 46px;
    white-space: nowrap;
    border: 0;
    border-bottom: 1px solid #f0f0f0;
    box-shadow: none;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin: 0 20px;
    margin-top: -1px;
    margin-bottom: 0;
    padding: 0 20px;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
  }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    position: relative;
    top: 1px;
    display: inline-block;
    vertical-align: bottom;
    border-bottom: 2px solid transparent;
  }
  .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0;
  }
  .ant-menu-horizontal > .ant-menu-item a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-menu-horizontal > .ant-menu-item a:hover {
    color: var(--primary);
  }
  .ant-menu-horizontal > .ant-menu-item a::before {
    bottom: -2px;
  }
  .ant-menu-horizontal > .ant-menu-item-selected a {
    color: var(--primary);
  }
  .ant-menu-horizontal::after {
    display: block;
    clear: both;
    height: 0;
    content: '\20';
  }
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item {
    position: relative;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 3px solid var(--primary);
    transform: scaleY(0.0001);
    opacity: 0;
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: '';
  }
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0 16px;
    overflow: hidden;
    line-height: 40px;
    text-overflow: ellipsis;
  }
  .ant-menu-vertical .ant-menu-submenu,
  .ant-menu-vertical-left .ant-menu-submenu,
  .ant-menu-vertical-right .ant-menu-submenu,
  .ant-menu-inline .ant-menu-submenu {
    padding-bottom: 0.02px;
  }
  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 8px;
  }
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
  }
  .ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 34px;
  }
  .ant-menu-inline {
    width: 100%;
  }
  .ant-menu-inline .ant-menu-selected::after,
  .ant-menu-inline .ant-menu-item-selected::after {
    transform: scaleY(1);
    opacity: 1;
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px);
  }
  .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 34px;
  }
  .ant-menu-inline-collapsed {
    width: 80px;
  }
  .ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
  .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    left: 0;
    padding: 0 32px;
    text-overflow: clip;
  }
  .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
  .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
  .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    display: none;
  }
  .ant-menu-inline-collapsed > .ant-menu-item .anticon,
  .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
  .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    margin: 0;
    font-size: 16px;
    line-height: 40px;
  }
  .ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
  .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
  .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .anticon
    + span,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
    display: inline-block;
    max-width: 0;
    opacity: 0;
  }
  .ant-menu-inline-collapsed .anticon {
    display: inline-block;
  }
  .ant-menu-inline-collapsed-tooltip {
    pointer-events: none;
  }
  .ant-menu-inline-collapsed-tooltip .anticon {
    display: none;
  }
  .ant-menu-inline-collapsed-tooltip a {
    color: rgba(255, 255, 255, 0.85);
  }
  .ant-menu-inline-collapsed .ant-menu-item-group-title {
    padding-right: 4px;
    padding-left: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-menu-item-group-list {
    margin: 0;
    padding: 0;
  }
  .ant-menu-item-group-list .ant-menu-item,
  .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 16px 0 28px;
  }
  .ant-menu-root.ant-menu-vertical,
  .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right,
  .ant-menu-root.ant-menu-inline {
    box-shadow: none;
  }
  .ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
  .ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
    font-size: 16px;
    text-align: center;
  }
  .ant-menu-sub.ant-menu-inline {
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    list-style-position: inside;
    list-style-type: disc;
  }
  .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-left: 32px;
  }
  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled {
    color: rgba(0, 0, 0, 0.25);
    background: none;
    border-color: transparent;
    cursor: not-allowed;
  }
  .ant-menu-item-disabled a,
  .ant-menu-submenu-disabled a {
    color: rgba(0, 0, 0, 0.25);
    pointer-events: none;
  }
  .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: rgba(0, 0, 0, 0.25);
  }
  .ant-layout-header .ant-menu {
    line-height: inherit;
  }
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #001529;
  }
  .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 0.45;
    transition: all 0.3s;
  }
  .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    background: #fff;
  }
  .ant-menu-dark.ant-menu-submenu-popup {
    background: transparent;
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #000c17;
  }
  .ant-menu-dark.ant-menu-horizontal {
    border-bottom: 0;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    top: 0;
    margin-top: 0;
    padding: 0 20px;
    border-color: #001529;
    border-bottom: 0;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: var(--primary);
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
    bottom: 0;
  }
  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark .ant-menu-item-group-title,
  .ant-menu-dark .ant-menu-item > a,
  .ant-menu-dark .ant-menu-item > span > a {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-menu-dark.ant-menu-inline,
  .ant-menu-dark.ant-menu-vertical,
  .ant-menu-dark.ant-menu-vertical-left,
  .ant-menu-dark.ant-menu-vertical-right {
    border-right: 0;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
  .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
  .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
    border-right: 0;
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
  }
  .ant-menu-dark .ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-submenu-active,
  .ant-menu-dark .ant-menu-submenu-open,
  .ant-menu-dark .ant-menu-submenu-selected,
  .ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
    background-color: transparent;
  }
  .ant-menu-dark .ant-menu-item:hover > a,
  .ant-menu-dark .ant-menu-item-active > a,
  .ant-menu-dark .ant-menu-submenu-active > a,
  .ant-menu-dark .ant-menu-submenu-open > a,
  .ant-menu-dark .ant-menu-submenu-selected > a,
  .ant-menu-dark .ant-menu-submenu-title:hover > a,
  .ant-menu-dark .ant-menu-item:hover > span > a,
  .ant-menu-dark .ant-menu-item-active > span > a,
  .ant-menu-dark .ant-menu-submenu-active > span > a,
  .ant-menu-dark .ant-menu-submenu-open > span > a,
  .ant-menu-dark .ant-menu-submenu-selected > span > a,
  .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
    opacity: 1;
  }
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
    background: #fff;
  }
  .ant-menu-dark .ant-menu-item:hover {
    background-color: transparent;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--primary);
  }
  .ant-menu-dark .ant-menu-item-selected {
    color: #fff;
    border-right: 0;
  }
  .ant-menu-dark .ant-menu-item-selected::after {
    border-right: 0;
  }
  .ant-menu-dark .ant-menu-item-selected > a,
  .ant-menu-dark .ant-menu-item-selected > span > a,
  .ant-menu-dark .ant-menu-item-selected > a:hover,
  .ant-menu-dark .ant-menu-item-selected > span > a:hover {
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item-selected .anticon {
    color: #fff;
  }
  .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: #fff;
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: var(--primary);
  }
  .ant-menu-dark .ant-menu-item-disabled,
  .ant-menu-dark .ant-menu-submenu-disabled,
  .ant-menu-dark .ant-menu-item-disabled > a,
  .ant-menu-dark .ant-menu-submenu-disabled > a,
  .ant-menu-dark .ant-menu-item-disabled > span > a,
  .ant-menu-dark .ant-menu-submenu-disabled > span > a {
    color: rgba(255, 255, 255, 0.35);
    opacity: 0.8;
  }
  .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(255, 255, 255, 0.35);
  }
  .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: rgba(255, 255, 255, 0.35);
  }
  .ant-menu.ant-menu-rtl {
    direction: rtl;
    text-align: right;
  }
  .ant-menu-rtl .ant-menu-item-group-title {
    text-align: right;
  }
  .ant-menu-rtl.ant-menu-inline,
  .ant-menu-rtl.ant-menu-vertical {
    border-right: none;
    border-left: 1px solid #f0f0f0;
  }
  .ant-menu-rtl.ant-menu-dark.ant-menu-inline,
  .ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
    border-left: none;
  }
  .ant-menu-rtl.ant-menu-vertical.ant-menu-sub,
  .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub,
  .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub {
    transform-origin: top right;
  }
  .ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
  .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
  .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
    transform-origin: top right;
  }
  .ant-menu-rtl .ant-menu-item .anticon,
  .ant-menu-rtl .ant-menu-submenu-title .anticon {
    margin-right: auto;
    margin-left: 10px;
  }
  .ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
  .ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
    margin-left: 0;
  }
  .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    right: auto;
    left: 16px;
  }
  .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    transform: rotate(-45deg) translateY(-2px);
  }
  .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(45deg) translateY(2px);
  }
  .ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
  .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
    right: auto;
    left: 0;
  }
  .ant-menu-rtl.ant-menu-vertical .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
  .ant-menu-rtl.ant-menu-inline .ant-menu-item,
  .ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
    text-align: right;
  }
  .ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
    padding-right: 0;
    padding-left: 34px;
  }
  .ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 16px;
    padding-left: 34px;
  }
  .ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
    padding: 0 32px;
  }
  .ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
  .ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 28px 0 16px;
  }
  .ant-menu-sub.ant-menu-inline {
    border: 0;
  }
  .ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-right: 32px;
    padding-left: 0;
  }
`
