import css from 'styled-jsx/css'

export const PaginationStyle = css.global`
  .ant-pagination {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
  }
  .ant-pagination ul,
  .ant-pagination ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .ant-pagination::after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: ' ';
  }
  .ant-pagination-total-text {
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
    vertical-align: middle;
  }
  .ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: 0;
    cursor: pointer;
    user-select: none;
  }
  .ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: rgba(0, 0, 0, 0.85);
    transition: none;
  }
  .ant-pagination-item a:hover {
    text-decoration: none;
  }
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: var(--primary);
    transition: all 0.3s;
  }
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: var(--primary);
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: var(--primary);
  }
  .ant-pagination-item-active a {
    color: var(--primary);
  }
  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover {
    border-color: #5a3cda;
  }
  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a {
    color: #5a3cda;
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    outline: 0;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container,
  .ant-pagination-jump-next .ant-pagination-item-container {
    position: relative;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--primary);
    font-size: 12px;
    letter-spacing: -1px;
    opacity: 0;
    transition: all 0.2s;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    color: rgba(0, 0, 0, 0.25);
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
    text-align: center;
    text-indent: 0.13em;
    opacity: 1;
    transition: all 0.2s;
  }
  .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
  .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
  .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
    opacity: 1;
  }
  .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
    opacity: 0;
  }
  .ant-pagination-prev,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin-right: 8px;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    font-family: Arial, Helvetica, sans-serif;
    outline: 0;
  }
  .ant-pagination-prev button,
  .ant-pagination-next button {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    user-select: none;
  }
  .ant-pagination-prev:hover button,
  .ant-pagination-next:hover button {
    border-color: #5a3cda;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;
    transition: all 0.3s;
  }
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: var(--primary);
    border-color: var(--primary);
  }
  .ant-pagination-disabled,
  .ant-pagination-disabled:hover,
  .ant-pagination-disabled:focus {
    cursor: not-allowed;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-pagination-slash {
    margin: 0 10px 0 5px;
  }
  .ant-pagination-options {
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle;
  }
  @media all and (-ms-high-contrast: none) {
    .ant-pagination-options *::-ms-backdrop,
    .ant-pagination-options {
      vertical-align: top;
    }
  }
  .ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    width: auto;
    margin-right: 8px;
  }
  .ant-pagination-options-quick-jumper {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    vertical-align: top;
  }
  .ant-pagination-options-quick-jumper input {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    width: 50px;
    margin: 0 8px;
  }
  .ant-pagination-options-quick-jumper input::-moz-placeholder {
    opacity: 1;
  }
  .ant-pagination-options-quick-jumper input::placeholder {
    color: #bfbfbf;
  }
  .ant-pagination-options-quick-jumper input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-pagination-options-quick-jumper input:hover {
    border-color: #5a3cda;
    border-right-width: 1px !important;
  }
  .ant-pagination-options-quick-jumper input:focus,
  .ant-pagination-options-quick-jumper input-focused {
    border-color: #5a3cda;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-pagination-options-quick-jumper input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-pagination-options-quick-jumper input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-pagination-options-quick-jumper input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-pagination-options-quick-jumper input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-pagination-options-quick-jumper input-borderless,
  .ant-pagination-options-quick-jumper input-borderless:hover,
  .ant-pagination-options-quick-jumper input-borderless:focus,
  .ant-pagination-options-quick-jumper input-borderless-focused,
  .ant-pagination-options-quick-jumper input-borderless-disabled,
  .ant-pagination-options-quick-jumper input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.ant-pagination-options-quick-jumper input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .ant-pagination-options-quick-jumper input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-pagination-options-quick-jumper input-sm {
    padding: 0px 7px;
  }
  .ant-pagination-simple .ant-pagination-prev,
  .ant-pagination-simple .ant-pagination-next {
    height: 24px;
    line-height: 24px;
    vertical-align: top;
  }
  .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
    height: 24px;
    background-color: transparent;
    border: 0;
  }
  .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
  .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination-simple .ant-pagination-simple-pager {
    display: inline-block;
    height: 24px;
    margin-right: 8px;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input {
    box-sizing: border-box;
    height: 100%;
    margin-right: 8px;
    padding: 0 6px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;
    transition: border-color 0.3s;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input:hover {
    border-color: var(--primary);
  }
  .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-pagination.mini .ant-pagination-total-text,
  .ant-pagination.mini .ant-pagination-simple-pager {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-item {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 22px;
  }
  .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
    background: transparent;
    border-color: transparent;
  }
  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
    background: transparent;
    border-color: transparent;
  }
  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
  .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-jump-prev,
  .ant-pagination.mini .ant-pagination-jump-next {
    height: 24px;
    margin-right: 0;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-options {
    margin-left: 2px;
  }
  .ant-pagination.mini .ant-pagination-options-size-changer {
    top: 0px;
  }
  .ant-pagination.mini .ant-pagination-options-quick-jumper {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-options-quick-jumper input {
    padding: 0px 7px;
    width: 44px;
  }
  .ant-pagination.ant-pagination-disabled {
    cursor: not-allowed;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border: none;
    cursor: not-allowed;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
    background: #dbdbdb;
    border-color: transparent;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
    color: #fff;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    background: transparent;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
    opacity: 0;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
    opacity: 1;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
    color: rgba(0, 0, 0, 0.25);
  }
  @media only screen and (max-width: 992px) {
    .ant-pagination-item-after-jump-prev,
    .ant-pagination-item-before-jump-next {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .ant-pagination-options {
      display: none;
    }
  }
  .ant-pagination-rtl {
    direction: rtl;
  }
  .ant-pagination-rtl .ant-pagination-total-text {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl .ant-pagination-item {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl .ant-pagination-prev,
  .ant-pagination-rtl .ant-pagination-jump-prev,
  .ant-pagination-rtl .ant-pagination-jump-next {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl .ant-pagination-slash {
    margin: 0 5px 0 10px;
  }
  .ant-pagination-rtl .ant-pagination-options {
    margin-right: 16px;
    margin-left: 0;
  }
  .ant-pagination-rtl .ant-pagination-options-size-changer.ant-select {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
    margin-right: 2px;
    margin-left: 0;
  }
`
