import css from 'styled-jsx/css'

export const DatePickerStyle = css.global`
  .ant-picker-panel {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    outline: none;
  }
  .ant-picker-panel-focused {
    border-color: var(--primary);
  }
  .ant-picker-decade-panel,
  .ant-picker-year-panel,
  .ant-picker-quarter-panel,
  .ant-picker-month-panel,
  .ant-picker-week-panel,
  .ant-picker-date-panel,
  .ant-picker-time-panel {
    display: flex;
    flex-direction: column;
    width: 280px;
  }
  .ant-picker-header {
    display: flex;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-picker-header > * {
    flex: none;
  }
  .ant-picker-header button {
    padding: 0;
    color: rgba(0, 0, 0, 0.25);
    line-height: 40px;
    background: transparent;
    border: 0;
    cursor: pointer;
    transition: color 0.3s;
  }
  .ant-picker-header > button {
    min-width: 1.6em;
    font-size: 14px;
  }
  .ant-picker-header > button:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-picker-header-view {
    flex: auto;
    font-weight: 500;
    line-height: 40px;
  }
  .ant-picker-header-view button {
    color: inherit;
    font-weight: inherit;
  }
  .ant-picker-header-view button:not(:first-child) {
    margin-left: 8px;
  }
  .ant-picker-header-view button:hover {
    color: var(--primary);
  }
  .ant-picker-prev-icon,
  .ant-picker-next-icon,
  .ant-picker-super-prev-icon,
  .ant-picker-super-next-icon {
    position: relative;
    display: inline-block;
    width: 7px;
    height: 7px;
  }
  .ant-picker-prev-icon::before,
  .ant-picker-next-icon::before,
  .ant-picker-super-prev-icon::before,
  .ant-picker-super-next-icon::before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 7px;
    height: 7px;
    border: 0 solid currentColor;
    border-width: 1.5px 0 0 1.5px;
    content: '';
  }
  .ant-picker-super-prev-icon::after,
  .ant-picker-super-next-icon::after {
    position: absolute;
    top: 4px;
    left: 4px;
    display: inline-block;
    width: 7px;
    height: 7px;
    border: 0 solid currentColor;
    border-width: 1.5px 0 0 1.5px;
    content: '';
  }
  .ant-picker-prev-icon,
  .ant-picker-super-prev-icon {
    transform: rotate(-45deg);
  }
  .ant-picker-next-icon,
  .ant-picker-super-next-icon {
    transform: rotate(135deg);
  }
  .ant-picker-content {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }
  .ant-picker-content th,
  .ant-picker-content td {
    position: relative;
    min-width: 24px;
    font-weight: 400;
  }
  .ant-picker-content th {
    height: 30px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 30px;
  }
  .ant-picker-cell {
    padding: 3px 0;
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  .ant-picker-cell-in-view {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-picker-cell-disabled {
    cursor: not-allowed;
  }
  .ant-picker-cell::before {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 1;
    height: 24px;
    transform: translateY(-50%);
    content: '';
  }
  .ant-picker-cell .ant-picker-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    transition: background 0.3s, border 0.3s;
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background: #f5f5f5;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border: 1px solid var(--primary);
    border-radius: 2px;
    content: '';
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range {
    position: relative;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #e6f7ff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: var(--primary);
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #e6f7ff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
    left: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
    right: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    position: absolute;
    top: 50%;
    z-index: 0;
    height: 24px;
    border-top: 1px dashed #7ec1ff;
    border-bottom: 1px dashed #7ec1ff;
    transform: translateY(-50%);
    content: '';
  }
  .ant-picker-cell-range-hover-start::after,
  .ant-picker-cell-range-hover-end::after,
  .ant-picker-cell-range-hover::after {
    right: 0;
    left: 2px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: #cbe6ff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-radius: 2px 0 0 2px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner {
    border-radius: 0 2px 2px 0;
  }
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: #cbe6ff;
    content: '';
  }
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
    right: -7px;
    left: 0;
  }
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    right: 0;
    left: -7px;
  }
  .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
    right: 50%;
  }
  .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
    left: 50%;
  }
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    left: 6px;
    border-left: 1px dashed #7ec1ff;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    right: 6px;
    border-right: 1px dashed #7ec1ff;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .ant-picker-cell-disabled {
    pointer-events: none;
  }
  .ant-picker-cell-disabled .ant-picker-cell-inner {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
  }
  .ant-picker-cell-disabled::before {
    background: #f5f5f5;
  }
  .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-picker-decade-panel .ant-picker-content,
  .ant-picker-year-panel .ant-picker-content,
  .ant-picker-quarter-panel .ant-picker-content,
  .ant-picker-month-panel .ant-picker-content {
    height: 264px;
  }
  .ant-picker-decade-panel .ant-picker-cell-inner,
  .ant-picker-year-panel .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-inner,
  .ant-picker-month-panel .ant-picker-cell-inner {
    padding: 0 8px;
  }
  .ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
    background: #f5f5f5;
  }
  .ant-picker-quarter-panel .ant-picker-content {
    height: 56px;
  }
  .ant-picker-footer {
    width: min-content;
    min-width: 100%;
    line-height: 38px;
    text-align: center;
    border-bottom: 1px solid transparent;
  }
  .ant-picker-panel .ant-picker-footer {
    border-top: 1px solid #f0f0f0;
  }
  .ant-picker-footer-extra {
    padding: 0 12px;
    line-height: 38px;
    text-align: left;
  }
  .ant-picker-footer-extra:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-picker-now {
    text-align: left;
  }
  .ant-picker-today-btn {
    color: var(--primary);
  }
  .ant-picker-today-btn:hover {
    color: #5a3cda;
  }
  .ant-picker-today-btn:active {
    color: #4527c4;
  }
  .ant-picker-today-btn.ant-picker-today-btn-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-picker-decade-panel .ant-picker-cell-inner {
    padding: 0 4px;
  }
  .ant-picker-decade-panel .ant-picker-cell::before {
    display: none;
  }
  .ant-picker-year-panel .ant-picker-body,
  .ant-picker-quarter-panel .ant-picker-body,
  .ant-picker-month-panel .ant-picker-body {
    padding: 0 8px;
  }
  .ant-picker-year-panel .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-inner,
  .ant-picker-month-panel .ant-picker-cell-inner {
    width: 60px;
  }
  .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
    left: 14px;
    border-left: 1px dashed #7ec1ff;
    border-radius: 2px 0 0 2px;
  }
  .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
    right: 14px;
    border-right: 1px dashed #7ec1ff;
    border-radius: 0 2px 2px 0;
  }
  .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
    right: 14px;
    border-right: 1px dashed #7ec1ff;
    border-radius: 0 2px 2px 0;
  }
  .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
    left: 14px;
    border-left: 1px dashed #7ec1ff;
    border-radius: 2px 0 0 2px;
  }
  .ant-picker-week-panel .ant-picker-body {
    padding: 8px 12px;
  }
  .ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
  .ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
    background: transparent !important;
  }
  .ant-picker-week-panel-row td {
    transition: background 0.3s;
  }
  .ant-picker-week-panel-row:hover td {
    background: #f5f5f5;
  }
  .ant-picker-week-panel-row-selected td,
  .ant-picker-week-panel-row-selected:hover td {
    background: var(--primary);
  }
  .ant-picker-week-panel-row-selected td.ant-picker-cell-week,
  .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
    color: rgba(255, 255, 255, 0.5);
  }
  .ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
  .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #fff;
  }
  .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
  .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
    color: #fff;
  }
  .ant-picker-date-panel .ant-picker-body {
    padding: 8px 12px;
  }
  .ant-picker-date-panel .ant-picker-content {
    width: 252px;
  }
  .ant-picker-date-panel .ant-picker-content th {
    width: 36px;
  }
  .ant-picker-datetime-panel {
    display: flex;
  }
  .ant-picker-datetime-panel .ant-picker-time-panel {
    border-left: 1px solid #f0f0f0;
  }
  .ant-picker-datetime-panel .ant-picker-date-panel,
  .ant-picker-datetime-panel .ant-picker-time-panel {
    transition: opacity 0.3s;
  }
  .ant-picker-datetime-panel-active .ant-picker-date-panel,
  .ant-picker-datetime-panel-active .ant-picker-time-panel {
    opacity: 0.3;
  }
  .ant-picker-datetime-panel-active .ant-picker-date-panel-active,
  .ant-picker-datetime-panel-active .ant-picker-time-panel-active {
    opacity: 1;
  }
  .ant-picker-time-panel {
    width: auto;
    min-width: auto;
  }
  .ant-picker-time-panel .ant-picker-content {
    display: flex;
    flex: auto;
    height: 224px;
  }
  .ant-picker-time-panel-column {
    flex: 1 0 auto;
    width: 56px;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    text-align: left;
    list-style: none;
    transition: background 0.3s;
  }
  .ant-picker-time-panel-column::after {
    display: block;
    height: 196px;
    content: '';
  }
  .ant-picker-datetime-panel .ant-picker-time-panel-column::after {
    height: 198px;
  }
  .ant-picker-time-panel-column:not(:first-child) {
    border-left: 1px solid #f0f0f0;
  }
  .ant-picker-time-panel-column-active {
    background: rgba(230, 247, 255, 0.2);
  }
  .ant-picker-time-panel-column:hover {
    overflow-y: auto;
  }
  .ant-picker-time-panel-column > li {
    margin: 0;
    padding: 0;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    display: block;
    width: 100%;
    height: 28px;
    margin: 0;
    padding: 0 0 0 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    border-radius: 0;
    cursor: pointer;
    transition: background 0.3s;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background: #f5f5f5;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #e6f7ff;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    cursor: not-allowed;
  }
  /* stylelint-disable-next-line */
  _:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
  :root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
  _:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
  :root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
    padding: 21px 0;
  }
  .ant-picker {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    padding: 4px 11px 4px;
    position: relative;
    display: inline-flex;
    align-items: center;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transition: border 0.3s, box-shadow 0.3s;
    font-size: 16px;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #5a3cda;
    border-right-width: 1px !important;
  }
  .ant-picker-focused {
    border-color: #5a3cda;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-picker.ant-picker-disabled {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-picker.ant-picker-disabled .ant-picker-suffix {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-picker.ant-picker-borderless {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-picker-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .ant-picker-input > input {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    flex: auto;
    min-width: 1px;
    height: auto;
    padding: 0;
    background: transparent;
    border: 0;
  }
  .ant-picker-input > input::-moz-placeholder {
    opacity: 1;
  }
  .ant-picker-input > input::placeholder {
    color: #bfbfbf;
  }
  .ant-picker-input > input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-picker-input > input:hover {
    border-color: #5a3cda;
    border-right-width: 1px !important;
  }
  .ant-picker-input > input:focus,
  .ant-picker-input > input-focused {
    border-color: #5a3cda;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-picker-input > input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-picker-input > input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-picker-input > input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-picker-input > input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-picker-input > input-borderless,
  .ant-picker-input > input-borderless:hover,
  .ant-picker-input > input-borderless:focus,
  .ant-picker-input > input-borderless-focused,
  .ant-picker-input > input-borderless-disabled,
  .ant-picker-input > input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.ant-picker-input > input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .ant-picker-input > input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-picker-input > input-sm {
    padding: 0px 7px;
  }
  .ant-picker-input > input:focus {
    box-shadow: none;
  }
  .ant-picker-input > input[disabled] {
    background: transparent;
  }
  .ant-picker-input:hover .ant-picker-clear {
    opacity: 1;
  }
  .ant-picker-input-placeholder > input {
    color: #bfbfbf;
  }
  .ant-picker-large {
    padding: 6.5px 11px 6.5px;
  }
  .ant-picker-large .ant-picker-input > input {
    font-size: 16px;
  }
  .ant-picker-small {
    padding: 0px 7px 0px;
  }
  .ant-picker-suffix {
    align-self: center;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 1;
    pointer-events: none;
  }
  .ant-picker-suffix > * {
    vertical-align: top;
  }
  .ant-picker-clear {
    position: absolute;
    top: 50%;
    right: 0;
    color: rgba(0, 0, 0, 0.25);
    line-height: 1;
    background: #fff;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s, color 0.3s;
  }
  .ant-picker-clear > * {
    vertical-align: top;
  }
  .ant-picker-clear:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-picker-separator {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 16px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    vertical-align: top;
    cursor: default;
  }
  .ant-picker-focused .ant-picker-separator {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
    cursor: not-allowed;
  }
  .ant-picker-range {
    position: relative;
    display: inline-flex;
  }
  .ant-picker-range .ant-picker-clear {
    right: 11px;
  }
  .ant-picker-range:hover .ant-picker-clear {
    opacity: 1;
  }
  .ant-picker-range .ant-picker-active-bar {
    bottom: -1px;
    height: 2px;
    margin-left: 11px;
    background: var(--primary);
    opacity: 0;
    transition: all 0.3s ease-out;
    pointer-events: none;
  }
  .ant-picker-range.ant-picker-focused .ant-picker-active-bar {
    opacity: 1;
  }
  .ant-picker-range-separator {
    align-items: center;
    padding: 0 8px;
    line-height: 1;
  }
  .ant-picker-range.ant-picker-small .ant-picker-clear {
    right: 7px;
  }
  .ant-picker-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    z-index: 1050;
  }
  .ant-picker-dropdown-hidden {
    display: none;
  }
  .ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
    top: 1.66666667px;
    display: block;
    transform: rotate(-45deg);
  }
  .ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
    bottom: 1.66666667px;
    display: block;
    transform: rotate(135deg);
  }
  .ant-picker-dropdown-range {
    padding: 6.66666667px 0;
  }
  .ant-picker-dropdown-range-hidden {
    display: none;
  }
  .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
    padding-top: 4px;
  }
  .ant-picker-ranges {
    margin-bottom: 0;
    padding: 4px 12px;
    overflow: hidden;
    line-height: 34px;
    text-align: left;
    list-style: none;
  }
  .ant-picker-ranges > li {
    display: inline-block;
  }
  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    color: var(--primary);
    background: #e6f7ff;
    border-color: #91d5ff;
    cursor: pointer;
  }
  .ant-picker-ranges .ant-picker-ok {
    float: right;
    margin-left: 8px;
  }
  .ant-picker-range-wrapper {
    display: flex;
  }
  .ant-picker-range-arrow {
    position: absolute;
    z-index: 1;
    display: none;
    width: 10px;
    height: 10px;
    margin-left: 16.5px;
    box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
    transition: left 0.3s ease-out;
  }
  .ant-picker-range-arrow::after {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 10px;
    height: 10px;
    border: 5px solid #f0f0f0;
    border-color: #fff #fff transparent transparent;
    content: '';
  }
  .ant-picker-panel-container {
    overflow: hidden;
    vertical-align: top;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    transition: margin 0.3s;
  }
  .ant-picker-panel-container .ant-picker-panels {
    display: inline-flex;
    flex-wrap: nowrap;
    direction: ltr;
  }
  .ant-picker-panel-container .ant-picker-panel {
    vertical-align: top;
    background: transparent;
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .ant-picker-panel-container .ant-picker-panel-focused {
    border-color: #f0f0f0;
  }
  .ant-picker-rtl {
    direction: rtl;
  }
  .ant-picker-rtl .ant-picker-suffix {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-picker-rtl .ant-picker-clear {
    right: auto;
    left: 0;
  }
  .ant-picker-rtl .ant-picker-separator {
    transform: rotate(180deg);
  }
  .ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-picker-rtl.ant-picker-range .ant-picker-clear {
    right: auto;
    left: 11px;
  }
  .ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
    margin-right: 11px;
    margin-left: 0;
  }
  .ant-picker-dropdown-rtl .ant-picker-ranges {
    text-align: right;
  }
  .ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
    float: left;
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-picker-panel-rtl {
    direction: rtl;
  }
  .ant-picker-panel-rtl .ant-picker-prev-icon,
  .ant-picker-panel-rtl .ant-picker-super-prev-icon {
    transform: rotate(135deg);
  }
  .ant-picker-panel-rtl .ant-picker-next-icon,
  .ant-picker-panel-rtl .ant-picker-super-next-icon {
    transform: rotate(-45deg);
  }
  .ant-picker-cell .ant-picker-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    transition: background 0.3s, border 0.3s;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
    right: 50%;
    left: 0;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
    right: 0;
    left: 50%;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
    right: 50%;
    left: 50%;
  }
  .ant-picker-panel-rtl
    .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after {
    right: 0;
    left: -7px;
  }
  .ant-picker-panel-rtl
    .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    right: -7px;
    left: 0;
  }
  .ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
    right: 0;
    left: 50%;
  }
  .ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
    right: 50%;
    left: 0;
  }
  .ant-picker-panel-rtl
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-radius: 0 2px 2px 0;
  }
  .ant-picker-panel-rtl
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner {
    border-radius: 2px 0 0 2px;
  }
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
  .ant-picker-panel-rtl
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    right: 6px;
    left: 0;
    border-right: 1px dashed #7ec1ff;
    border-left: none;
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
  }
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  .ant-picker-panel-rtl
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    right: 0;
    left: 6px;
    border-right: none;
    border-left: 1px dashed #7ec1ff;
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
  }
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  .ant-picker-panel-rtl
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    right: 6px;
    border-right: 1px dashed #7ec1ff;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after {
    left: 6px;
    border-left: 1px dashed #7ec1ff;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .ant-picker-dropdown-rtl .ant-picker-footer-extra {
    direction: rtl;
    text-align: right;
  }
  .ant-picker-panel-rtl .ant-picker-time-panel {
    direction: ltr;
  }
`
