import css from 'styled-jsx/css'

export const AntdStyle = css.global`
  [class^='ant-']::-ms-clear,
  [class*='ant-']::-ms-clear,
  [class^='ant-'] input::-ms-clear,
  [class*='ant-'] input::-ms-clear,
  [class^='ant-'] input::-ms-reveal,
  [class*='ant-'] input::-ms-reveal {
    display: none;
  }
  [class^='ant-'],
  [class*='ant-'],
  [class^='ant-'] *,
  [class*='ant-'] *,
  [class^='ant-'] *::before,
  [class*='ant-'] *::before,
  [class^='ant-'] *::after,
  [class*='ant-'] *::after {
    box-sizing: border-box;
  }
  body [tabindex='-1']:focus {
    outline: none;
  }
  .ant-tag-blue {
    color: var(--primary);
    background: #e6f7ff;
    margin: 0 8px 0 0;
    padding: 0 7px;
    border-radius: 2px;
    white-space: nowrap;
  }
  .ant-tag-red {
    color: #ff4d4f;
    background: #fff1f0;
    margin: 0 8px 0 0;
    padding: 0 7px;
    border-radius: 2px;
    white-space: nowrap;
  }
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  [class^='ant-']::-ms-clear,
  [class*='ant-']::-ms-clear,
  [class^='ant-'] input::-ms-clear,
  [class*='ant-'] input::-ms-clear,
  [class^='ant-'] input::-ms-reveal,
  [class*='ant-'] input::-ms-reveal {
    display: none;
  }
  [class^='ant-'],
  [class*='ant-'],
  [class^='ant-'] *,
  [class*='ant-'] *,
  [class^='ant-'] *::before,
  [class*='ant-'] *::before,
  [class^='ant-'] *::after,
  [class*='ant-'] *::after {
    box-sizing: border-box;
  }
  /* stylelint-disable at-rule-no-unknown */
  html,
  body {
    width: 100%;
    height: 100%;
  }
  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  @-ms-viewport {
    width: device-width;
  }
  body {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-variant: tabular-nums;
    line-height: 1.5715;
    background-color: #fff;
    font-feature-settings: 'tnum';
  }
  body [tabindex='-1']:focus {
    outline: none;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }
  p {
    margin-top: 0;
    margin-bottom: 1em;
  }
  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    border-bottom: 0;
    cursor: help;
  }
  address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit;
  }
  input[type='text'],
  input[type='password'],
  input[type='number'],
  textarea {
    -webkit-appearance: none;
  }
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1em;
  }
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  dt {
    font-weight: 500;
  }
  dd {
    margin-bottom: 0.5em;
    margin-left: 0;
  }
  blockquote {
    margin: 0 0 1em;
  }
  dfn {
    font-style: italic;
  }
  b,
  strong {
    font-weight: bolder;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: var(--primary);
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }
  a:hover {
    color: #5a3cda;
  }
  a:active {
    color: #4527c4;
  }
  a:active,
  a:hover {
    text-decoration: none;
    outline: 0;
  }
  a:focus {
    text-decoration: none;
    outline: 0;
  }
  a[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    pointer-events: none;
  }
  pre,
  code,
  kbd,
  samp {
    font-size: 1em;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  }
  pre {
    margin-top: 0;
    margin-bottom: 1em;
    overflow: auto;
  }
  figure {
    margin: 0 0 1em;
  }
  img {
    vertical-align: middle;
    border-style: none;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  a,
  area,
  button,
  [role='button'],
  input:not([type='range']),
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }
  table {
    border-collapse: collapse;
  }
  caption {
    padding-top: 0.75em;
    padding-bottom: 0.3em;
    color: rgba(0, 0, 0, 0.45);
    text-align: left;
    caption-side: bottom;
  }
  th {
    text-align: inherit;
  }
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  html [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  input[type='radio'],
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='month'] {
    -webkit-appearance: listbox;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.5em;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    line-height: inherit;
    white-space: normal;
  }
  progress {
    vertical-align: baseline;
  }
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  [type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  [type='search']::-webkit-search-cancel-button,
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  output {
    display: inline-block;
  }
  summary {
    display: list-item;
  }
  template {
    display: none;
  }
  body [hidden] {
    display: none;
  }
  mark {
    padding: 0.2em;
    background-color: #feffe6;
  }
  ::selection {
    color: #fff;
    background: var(--primary)
  }
  .clearfix::before {
    display: table;
    content: '';
  }
  .clearfix::after {
    display: table;
    clear: both;
    content: '';
  }
  .anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .anticon > * {
    line-height: 1;
  }
  .anticon svg {
    display: inline-block;
  }
  .anticon::before {
    display: none;
  }
  .anticon .anticon-icon {
    display: block;
  }
  .anticon[tabindex] {
    cursor: pointer;
  }
  .anticon-spin::before {
    display: inline-block;
    animation: loadingCircle 1s infinite linear;
  }
  .anticon-spin {
    display: inline-block;
    animation: loadingCircle 1s infinite linear;
  }
  .fade-enter,
  .fade-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .fade-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .fade-enter.fade-enter-active,
  .fade-appear.fade-appear-active {
    animation-name: antFadeIn;
    animation-play-state: running;
  }
  .fade-leave.fade-leave-active {
    animation-name: antFadeOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .fade-enter,
  .fade-appear {
    opacity: 0;
    animation-timing-function: linear;
  }
  .fade-leave {
    animation-timing-function: linear;
  }
  @keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .move-up-enter,
  .move-up-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .move-up-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .move-up-enter.move-up-enter-active,
  .move-up-appear.move-up-appear-active {
    animation-name: antMoveUpIn;
    animation-play-state: running;
  }
  .move-up-leave.move-up-leave-active {
    animation-name: antMoveUpOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .move-up-enter,
  .move-up-appear {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .move-up-leave {
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .move-down-enter,
  .move-down-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .move-down-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .move-down-enter.move-down-enter-active,
  .move-down-appear.move-down-appear-active {
    animation-name: antMoveDownIn;
    animation-play-state: running;
  }
  .move-down-leave.move-down-leave-active {
    animation-name: antMoveDownOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .move-down-enter,
  .move-down-appear {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .move-down-leave {
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .move-left-enter,
  .move-left-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .move-left-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .move-left-enter.move-left-enter-active,
  .move-left-appear.move-left-appear-active {
    animation-name: antMoveLeftIn;
    animation-play-state: running;
  }
  .move-left-leave.move-left-leave-active {
    animation-name: antMoveLeftOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .move-left-enter,
  .move-left-appear {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .move-left-leave {
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .move-right-enter,
  .move-right-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .move-right-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .move-right-enter.move-right-enter-active,
  .move-right-appear.move-right-appear-active {
    animation-name: antMoveRightIn;
    animation-play-state: running;
  }
  .move-right-leave.move-right-leave-active {
    animation-name: antMoveRightOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .move-right-enter,
  .move-right-appear {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .move-right-leave {
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  @keyframes antMoveDownIn {
    0% {
      transform: translateY(100%);
      transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveDownOut {
    0% {
      transform: translateY(0%);
      transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      transform: translateY(100%);
      transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveLeftIn {
    0% {
      transform: translateX(-100%);
      transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      transform: translateX(0%);
      transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveLeftOut {
    0% {
      transform: translateX(0%);
      transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      transform: translateX(-100%);
      transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveRightIn {
    0% {
      transform: translateX(100%);
      transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      transform: translateX(0%);
      transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveRightOut {
    0% {
      transform: translateX(0%);
      transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      transform: translateX(100%);
      transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveUpIn {
    0% {
      transform: translateY(-100%);
      transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveUpOut {
    0% {
      transform: translateY(0%);
      transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes loadingCircle {
    100% {
      transform: rotate(360deg);
    }
  }
  [ant-click-animating='true'],
  [ant-click-animating-without-extra-node='true'] {
    position: relative;
  }
  html {
    --antd-wave-shadow-color: var(--primary);
    --scroll-bar: 0;
  }
  [ant-click-animating-without-extra-node='true']::after,
  .ant-click-animating-node {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow: 0 0 0 0 var(--primary)
    box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
    opacity: 0.2;
    animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-fill-mode: forwards;
    content: '';
    pointer-events: none;
  }
  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 var(--primary)
      box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    }
  }
  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
  .slide-up-enter,
  .slide-up-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .slide-up-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .slide-up-enter.slide-up-enter-active,
  .slide-up-appear.slide-up-appear-active {
    animation-name: antSlideUpIn;
    animation-play-state: running;
  }
  .slide-up-leave.slide-up-leave-active {
    animation-name: antSlideUpOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .slide-up-enter,
  .slide-up-appear {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .slide-up-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .slide-down-enter,
  .slide-down-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .slide-down-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .slide-down-enter.slide-down-enter-active,
  .slide-down-appear.slide-down-appear-active {
    animation-name: antSlideDownIn;
    animation-play-state: running;
  }
  .slide-down-leave.slide-down-leave-active {
    animation-name: antSlideDownOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .slide-down-enter,
  .slide-down-appear {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .slide-down-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .slide-left-enter,
  .slide-left-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .slide-left-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .slide-left-enter.slide-left-enter-active,
  .slide-left-appear.slide-left-appear-active {
    animation-name: antSlideLeftIn;
    animation-play-state: running;
  }
  .slide-left-leave.slide-left-leave-active {
    animation-name: antSlideLeftOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .slide-left-enter,
  .slide-left-appear {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .slide-left-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .slide-right-enter,
  .slide-right-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .slide-right-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .slide-right-enter.slide-right-enter-active,
  .slide-right-appear.slide-right-appear-active {
    animation-name: antSlideRightIn;
    animation-play-state: running;
  }
  .slide-right-leave.slide-right-leave-active {
    animation-name: antSlideRightOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .slide-right-enter,
  .slide-right-appear {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .slide-right-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  @keyframes antSlideUpIn {
    0% {
      transform: scaleY(0.8);
      transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideUpOut {
    0% {
      transform: scaleY(1);
      transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      transform: scaleY(0.8);
      transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideDownIn {
    0% {
      transform: scaleY(0.8);
      transform-origin: 100% 100%;
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @keyframes antSlideDownOut {
    0% {
      transform: scaleY(1);
      transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      transform: scaleY(0.8);
      transform-origin: 100% 100%;
      opacity: 0;
    }
  }
  @keyframes antSlideLeftIn {
    0% {
      transform: scaleX(0.8);
      transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      transform: scaleX(1);
      transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideLeftOut {
    0% {
      transform: scaleX(1);
      transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      transform: scaleX(0.8);
      transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideRightIn {
    0% {
      transform: scaleX(0.8);
      transform-origin: 100% 0%;
      opacity: 0;
    }
    100% {
      transform: scaleX(1);
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideRightOut {
    0% {
      transform: scaleX(1);
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      transform: scaleX(0.8);
      transform-origin: 100% 0%;
      opacity: 0;
    }
  }
  .zoom-enter,
  .zoom-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-enter.zoom-enter-active,
  .zoom-appear.zoom-appear-active {
    animation-name: antZoomIn;
    animation-play-state: running;
  }
  .zoom-leave.zoom-leave-active {
    animation-name: antZoomOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-enter,
  .zoom-appear {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-enter-prepare,
  .zoom-appear-prepare {
    transform: none;
  }
  .zoom-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-big-enter,
  .zoom-big-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-big-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-big-enter.zoom-big-enter-active,
  .zoom-big-appear.zoom-big-appear-active {
    animation-name: antZoomBigIn;
    animation-play-state: running;
  }
  .zoom-big-leave.zoom-big-leave-active {
    animation-name: antZoomBigOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-big-enter,
  .zoom-big-appear {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-big-enter-prepare,
  .zoom-big-appear-prepare {
    transform: none;
  }
  .zoom-big-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-big-fast-enter,
  .zoom-big-fast-appear {
    animation-duration: 0.1s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-big-fast-leave {
    animation-duration: 0.1s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-big-fast-enter.zoom-big-fast-enter-active,
  .zoom-big-fast-appear.zoom-big-fast-appear-active {
    animation-name: antZoomBigIn;
    animation-play-state: running;
  }
  .zoom-big-fast-leave.zoom-big-fast-leave-active {
    animation-name: antZoomBigOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-big-fast-enter,
  .zoom-big-fast-appear {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-big-fast-enter-prepare,
  .zoom-big-fast-appear-prepare {
    transform: none;
  }
  .zoom-big-fast-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-up-enter,
  .zoom-up-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-up-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-up-enter.zoom-up-enter-active,
  .zoom-up-appear.zoom-up-appear-active {
    animation-name: antZoomUpIn;
    animation-play-state: running;
  }
  .zoom-up-leave.zoom-up-leave-active {
    animation-name: antZoomUpOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-up-enter,
  .zoom-up-appear {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-up-enter-prepare,
  .zoom-up-appear-prepare {
    transform: none;
  }
  .zoom-up-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-down-enter,
  .zoom-down-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-down-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-down-enter.zoom-down-enter-active,
  .zoom-down-appear.zoom-down-appear-active {
    animation-name: antZoomDownIn;
    animation-play-state: running;
  }
  .zoom-down-leave.zoom-down-leave-active {
    animation-name: antZoomDownOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-down-enter,
  .zoom-down-appear {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-down-enter-prepare,
  .zoom-down-appear-prepare {
    transform: none;
  }
  .zoom-down-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-left-enter,
  .zoom-left-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-left-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-left-enter.zoom-left-enter-active,
  .zoom-left-appear.zoom-left-appear-active {
    animation-name: antZoomLeftIn;
    animation-play-state: running;
  }
  .zoom-left-leave.zoom-left-leave-active {
    animation-name: antZoomLeftOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-left-enter,
  .zoom-left-appear {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-left-enter-prepare,
  .zoom-left-appear-prepare {
    transform: none;
  }
  .zoom-left-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-right-enter,
  .zoom-right-appear {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-right-leave {
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .zoom-right-enter.zoom-right-enter-active,
  .zoom-right-appear.zoom-right-appear-active {
    animation-name: antZoomRightIn;
    animation-play-state: running;
  }
  .zoom-right-leave.zoom-right-leave-active {
    animation-name: antZoomRightOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .zoom-right-enter,
  .zoom-right-appear {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-right-enter-prepare,
  .zoom-right-appear-prepare {
    transform: none;
  }
  .zoom-right-leave {
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  @keyframes antZoomIn {
    0% {
      transform: scale(0.2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomOut {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.2);
      opacity: 0;
    }
  }
  @keyframes antZoomBigIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomBigOut {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
      opacity: 0;
    }
  }
  @keyframes antZoomUpIn {
    0% {
      transform: scale(0.8);
      transform-origin: 50% 0%;
      opacity: 0;
    }
    100% {
      transform: scale(1);
      transform-origin: 50% 0%;
    }
  }
  @keyframes antZoomUpOut {
    0% {
      transform: scale(1);
      transform-origin: 50% 0%;
    }
    100% {
      transform: scale(0.8);
      transform-origin: 50% 0%;
      opacity: 0;
    }
  }
  @keyframes antZoomLeftIn {
    0% {
      transform: scale(0.8);
      transform-origin: 0% 50%;
      opacity: 0;
    }
    100% {
      transform: scale(1);
      transform-origin: 0% 50%;
    }
  }
  @keyframes antZoomLeftOut {
    0% {
      transform: scale(1);
      transform-origin: 0% 50%;
    }
    100% {
      transform: scale(0.8);
      transform-origin: 0% 50%;
      opacity: 0;
    }
  }
  @keyframes antZoomRightIn {
    0% {
      transform: scale(0.8);
      transform-origin: 100% 50%;
      opacity: 0;
    }
    100% {
      transform: scale(1);
      transform-origin: 100% 50%;
    }
  }
  @keyframes antZoomRightOut {
    0% {
      transform: scale(1);
      transform-origin: 100% 50%;
    }
    100% {
      transform: scale(0.8);
      transform-origin: 100% 50%;
      opacity: 0;
    }
  }
  @keyframes antZoomDownIn {
    0% {
      transform: scale(0.8);
      transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      transform: scale(1);
      transform-origin: 50% 100%;
    }
  }
  @keyframes antZoomDownOut {
    0% {
      transform: scale(1);
      transform-origin: 50% 100%;
    }
    100% {
      transform: scale(0.8);
      transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  .ant-motion-collapse-legacy {
    overflow: hidden;
  }
  .ant-motion-collapse-legacy-active {
    transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-motion-collapse {
    overflow: hidden;
    transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-modal,
  .ant-image-preview {
    pointer-events: none;
  }
  .ant-modal.zoom-enter,
  .ant-modal.zoom-appear,
  .ant-image-preview.zoom-enter,
  .ant-image-preview.zoom-appear {
    transform: none;
    opacity: 0;
    animation-duration: 0.3s;
    user-select: none;
  }
  .ant-modal-mask,
  .ant-image-preview-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    filter: alpha(opacity=50);
  }
  .ant-modal-mask-hidden,
  .ant-image-preview-mask-hidden {
    display: none;
  }
  .ant-modal-wrap,
  .ant-image-preview-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
  }
  .ant-notification {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: fixed;
    z-index: 1010;
    max-width: calc(100vw - 32px);
    margin-right: 24px;
  }
  .ant-notification-topLeft,
  .ant-notification-bottomLeft {
    margin-right: 0;
    margin-left: 24px;
  }
  .ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
    animation-name: NotificationLeftFadeIn;
  }
  .ant-notification-close-icon {
    font-size: 14px;
    cursor: pointer;
  }
  .ant-notification-hook-holder,
  .ant-notification-notice {
    position: relative;
    width: 384px;
    margin-bottom: 16px;
    margin-left: auto;
    overflow: hidden;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-notification-topLeft .ant-notification-hook-holder,
  .ant-notification-topLeft .ant-notification-notice,
  .ant-notification-bottomLeft .ant-notification-hook-holder,
  .ant-notification-bottomLeft .ant-notification-notice {
    margin-right: auto;
    margin-left: 0;
  }
  .ant-notification-hook-holder > .ant-notification-notice {
    margin-bottom: 0;
    box-shadow: none;
  }
  .ant-notification-notice {
    padding: 16px 24px;
    line-height: 1.5715;
  }
  .ant-notification-notice-message {
    display: inline-block;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 24px;
  }
  .ant-notification-notice-message-single-line-auto-margin {
    display: block;
    width: calc(384px - 24px * 2 - 24px - 48px - 100%);
    max-width: 4px;
    background-color: transparent;
    pointer-events: none;
  }
  .ant-notification-notice-message-single-line-auto-margin::before {
    display: block;
    content: '';
  }
  .ant-notification-notice-description {
    font-size: 14px;
  }
  .ant-notification-notice-closable .ant-notification-notice-message {
    padding-right: 24px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 4px;
    margin-left: 48px;
    font-size: 16px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 48px;
    font-size: 14px;
  }
  .ant-notification-notice-icon {
    position: absolute;
    margin-left: 4px;
    font-size: 24px;
    line-height: 24px;
  }
  .anticon.ant-notification-notice-icon-success {
    color: #52c41a;
  }
  .anticon.ant-notification-notice-icon-info {
    color: var(--primary);
  }
  .anticon.ant-notification-notice-icon-warning {
    color: #faad14;
  }
  .anticon.ant-notification-notice-icon-error {
    color: #ff4d4f;
  }
  .ant-notification-notice-close {
    position: absolute;
    top: 16px;
    right: 22px;
    color: rgba(0, 0, 0, 0.45);
    outline: none;
  }
  .ant-notification-notice-close:hover {
    color: rgba(0, 0, 0, 0.67);
  }
  .ant-notification-notice-btn {
    float: right;
    margin-top: 16px;
  }
  .ant-notification .notification-fade-effect {
    animation-duration: 0.24s;
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-fill-mode: both;
  }
  .ant-notification-fade-enter,
  .ant-notification-fade-appear {
    opacity: 0;
    animation-duration: 0.24s;
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .ant-notification-fade-leave {
    animation-duration: 0.24s;
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-fill-mode: both;
    animation-duration: 0.2s;
    animation-play-state: paused;
  }
  .ant-notification-fade-enter.ant-notification-fade-enter-active,
  .ant-notification-fade-appear.ant-notification-fade-appear-active {
    animation-name: NotificationFadeIn;
    animation-play-state: running;
  }
  .ant-notification-fade-leave.ant-notification-fade-leave-active {
    animation-name: NotificationFadeOut;
    animation-play-state: running;
  }
  @keyframes NotificationFadeIn {
    0% {
      left: 384px;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationLeftFadeIn {
    0% {
      right: 384px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes NotificationFadeOut {
    0% {
      max-height: 150px;
      margin-bottom: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
  .ant-notification-rtl {
    direction: rtl;
  }
  .ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
    padding-right: 0;
    padding-left: 24px;
  }
  .ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-right: 48px;
    margin-left: 0;
  }
  .ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-right: 48px;
    margin-left: 0;
  }
  .ant-notification-rtl .ant-notification-notice-icon {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-notification-rtl .ant-notification-notice-close {
    right: auto;
    left: 22px;
  }
  .ant-notification-rtl .ant-notification-notice-btn {
    float: left;
  }

  .ant-input {
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
  }

  .ant-input-affix-wrapper {
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
  }
`
