import css from 'styled-jsx/css'

export const PartnerSectionStyle = css.global`
  .partner-section {
    padding: 80px 0;
    background-color: var(--purple-10);
  }
  .partner-section .heading {
    font-size: 36px;
    line-height: 46px;
    color: #262626;
    margin-bottom: 46px;
    text-align: center;
  }
  .partner-section .partner-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .partner-section .image-wrapper {
    flex: 1 0 155px;
    height: 120px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    .partner-section {
      padding: 40px 0;
    }
    .partner-section .heading {
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 32px;
    }

    .partner-section .partner-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 16px;
    }
  }
`
