import css from 'styled-jsx/css'

export const VariableStyle = css.global`
  :root {
    --layout-header-height: 64px;
    --layout-footer-height: 58px;
    --layout-content-min-height: calc(100vh - var(--layout-footer-height) - var(--layout-header-height));
    --black: #000;
    --white: #fff;
    --primary: #6d4fed;
    --blue: #1890ff;
    --grey-5: #d9d9d9;
    --grey-6: #bfbfbf;
    --grey-7: #8c8c8c;
    --grey-8: #595959;
    --grey-9: #262626;
    --red: #ea4335;
    --neutral: #f6f8fc;
    --highlight: #fa8c16;
    --gray-400: #9ca3af;
    --gray-600: #474c5e;
    --gray-700: #313543;
    --gray-900: #1d1e27;
    --purple-10: #f6f4fa;
    --purple-50: #e7e5ff;
    --purple-200: #b6b1ff;
  }
`
