import css from 'styled-jsx/css'

export const InputNumberStyle = css.global`
  .ant-input-number {
    box-sizing: border-box;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    transition: all 0.3s;
    display: inline-block;
    width: 90px;
    margin: 0;
    padding: 0;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }
  .ant-input-number::-moz-placeholder {
    opacity: 1;
  }
  .ant-input-number::placeholder {
    color: #bfbfbf;
  }
  .ant-input-number:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-number:hover {
    border-color: #5a3cda;
    border-right-width: 1px !important;
  }
  .ant-input-number:focus,
  .ant-input-number-focused {
    border-color: #5a3cda;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-input-number-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-number-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-number[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-number[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-number-borderless,
  .ant-input-number-borderless:hover,
  .ant-input-number-borderless:focus,
  .ant-input-number-borderless-focused,
  .ant-input-number-borderless-disabled,
  .ant-input-number-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.ant-input-number {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .ant-input-number-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-input-number-sm {
    padding: 0px 7px;
  }
  .ant-input-number-handler {
    position: relative;
    display: block;
    width: 100%;
    height: 50%;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold;
    line-height: 0;
    text-align: center;
    transition: all 0.1s linear;
  }
  .ant-input-number-handler:active {
    background: #f4f4f4;
  }
  .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
  .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: #5a3cda;
  }
  .ant-input-number-handler-up-inner,
  .ant-input-number-handler-down-inner {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 4px;
    width: 12px;
    height: 12px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 12px;
    transition: all 0.1s linear;
    user-select: none;
  }
  .ant-input-number-handler-up-inner > *,
  .ant-input-number-handler-down-inner > * {
    line-height: 1;
  }
  .ant-input-number-handler-up-inner svg,
  .ant-input-number-handler-down-inner svg {
    display: inline-block;
  }
  .ant-input-number-handler-up-inner::before,
  .ant-input-number-handler-down-inner::before {
    display: none;
  }
  .ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
  .ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
  .ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
  .ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
    display: block;
  }
  .ant-input-number:hover {
    border-color: #5a3cda;
    border-right-width: 1px !important;
  }
  .ant-input-number:hover + .ant-form-item-children-icon {
    opacity: 0;
    transition: opacity 0.24s linear 0.24s;
  }
  .ant-input-number-focused {
    border-color: #5a3cda;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-input-number-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-number-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-number-disabled .ant-input-number-input {
    cursor: not-allowed;
  }
  .ant-input-number-disabled .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-readonly .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input {
    width: 100%;
    height: 30px;
    padding: 0 11px;
    text-align: left;
    background-color: transparent;
    border: 0;
    border-radius: 2px;
    outline: 0;
    transition: all 0.3s linear;
    -moz-appearance: textfield !important;
  }
  .ant-input-number-input::-moz-placeholder {
    opacity: 1;
  }
  .ant-input-number-input::placeholder {
    color: #bfbfbf;
  }
  .ant-input-number-input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-number-input[type='number']::-webkit-inner-spin-button,
  .ant-input-number-input[type='number']::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
  .ant-input-number-lg {
    padding: 0;
    font-size: 16px;
  }
  .ant-input-number-lg input {
    height: 38px;
  }
  .ant-input-number-sm {
    padding: 0;
  }
  .ant-input-number-sm input {
    height: 22px;
    padding: 0 7px;
  }
  .ant-input-number-handler-wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 100%;
    background: #fff;
    border-left: 1px solid #d9d9d9;
    border-radius: 0 2px 2px 0;
    opacity: 0;
    transition: opacity 0.24s linear 0.1s;
  }
  .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
  .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
    display: inline-block;
    font-size: 7px;
    min-width: auto;
    margin-right: 0;
  }
  .ant-input-number-handler-wrap:hover .ant-input-number-handler {
    height: 40%;
  }
  .ant-input-number:hover .ant-input-number-handler-wrap {
    opacity: 1;
  }
  .ant-input-number-handler-up {
    border-top-right-radius: 2px;
    cursor: pointer;
  }
  .ant-input-number-handler-up-inner {
    top: 50%;
    margin-top: -5px;
    text-align: center;
  }
  .ant-input-number-handler-up:hover {
    height: 60% !important;
  }
  .ant-input-number-handler-down {
    top: 0;
    border-top: 1px solid #d9d9d9;
    border-bottom-right-radius: 2px;
    cursor: pointer;
  }
  .ant-input-number-handler-down-inner {
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
  }
  .ant-input-number-handler-down:hover {
    height: 60% !important;
  }
  .ant-input-number-handler-up-disabled,
  .ant-input-number-handler-down-disabled {
    cursor: not-allowed;
  }
  .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
  .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-input-number-rtl {
    direction: rtl;
  }
  .ant-input-number-rtl .ant-input-number-handler-wrap {
    right: auto;
    left: 0;
    border-right: 1px solid #d9d9d9;
    border-left: 0;
    border-radius: 2px 0 0 2px;
  }
  .ant-input-number-rtl .ant-input-number-input {
    direction: ltr;
    text-align: right;
  }
`
