import css from 'styled-jsx/css'

export const TextStyle = css.global`
  .text-capitalize {
    text-transform: capitalize;
  }

  .text-center {
    text-align: center;
  }

  .text-12-gray {
    font-size: 12px;
    color: #595959;
  }

  .text-12-gray-6 {
    font-size: 12px;
    color: #bfbfbf;
  }

  .text-14-gray {
    font-size: 14px;
    color: #262626;
  }

  .text-14-gray-7 {
    font-size: 14px;
    color: #8c8c8c;
  }

  .text-14-gray-8 {
    font-size: 14px;
    color: #595959;
  }

  .text-14-gray-9 {
    font-size: 14px;
    color: #262626;
  }

  .text-14-gray-8-bold {
    font-size: 14px;
    font-weight: 600;
    color: #595959;
  }

  .text-14-gray-8-bold a:hover {
    color: #595959;
  }

  .text-14-gray-9-bold {
    font-size: 14px;
    font-weight: 600;
    color: #262626;
  }

  .text-14-bold {
    font-size: 14px;
    font-weight: 600;
  }

  .text-16-bold {
    font-weight: 500;
    font-size: 16px;
    color: #262626;
  }

  .text-16 {
    font-size: 16px;
    line-height: 24px;
  }

  .text-20-bold {
    font-weight: 500;
    font-size: 20px;
    color: #262626;
  }

  .text-20-bold-desc {
    margin: -12px 0 8px 0;
  }

  .text-link-16-bold {
    font-weight: 600;
    font-size: 16px;
    color: var(--primary);
  }

  .text-12-red {
    font-size: 12px;
    color: #f5222d;
  }

  .text-16-blue {
    font-size: 16px;
    color: var(--primary);
  }

  .line-height-24 {
    line-height: 24px;
  }

  .text-2-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .color-gray-7 {
    color: #8c8c8c;
    font-size: 16px;
  }

  body .text-blue {
    color: var(--primary);
  }
`
